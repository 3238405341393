.cc-cookies{
	position:fixed;
	bottom:0;
	left:0;
	background:$main-gradient1;
	width:100%;
	border-top:1px solid $grey-lightest;
	padding:10px;
	text-align:center;
	@include boxSizing(border-box);
	font-weight:200;
	z-index:10000000;
	color:$grey;
	line-height:1.8em;
	@include media(mobile){
		line-height: 1.2em;
		background:rgba($main-gradient1,0.95);
	}

	.cc-cookie-accept{
		background-color:$blue;
		padding:1px 10px;
		font-weight: normal;
		color:$color-white;
		
		&:hover{
			background-color:$dark;
			color:$color-white;
		}
	}//cc-cookie-accept
	a{
		display: inline-block;
		&:hover{
			color:$dark;
		}

		@include media(mobile){
			display:block;
			width:initial;
			margin:auto;
			&.cc-cookie-accept{
				width:115px;
				padding:4px 10px;
				margin:3px auto;
			}		
		}
	}//a
}//cc-cookies
