// BASICS -- things that will be on every site (color, buttons, forms, reset, conditionals, global mixins - can be overwritten in styles)

@import "basic/_reset.scss";
@import "basic/_conditionals.scss";
@import "basic/_mixins.scss";
@import "basic/_toolbox.scss";
@import "basic/_colours.scss";
// @import "basic/_css3.scss";
// @import "basic/_css3_alt.scss";
// @import "basic/_custom-mixins.scss";
// @import "basic/_z-index.scss";


// PLUGINS -- all plugin associated or styles taken from elsewhere
@import "plugins/owlcarousel/_owlcarousel.scss";
@import "plugins/fontawesome/_font-awesome.scss";
// @import "plugins/_forms.scss";
// @import "plugins/_bootstrap-select.scss";
// @import "plugins/_magnific-popup.scss";
// @import "plugins/_bootstrap-modal.scss";
// @import "plugins/_buttons.scss";
// @import "plugins/_cookie-bar.scss";
// @import "plugins/_plugin_qtip.scss";
// @import "plugins/_js-slider.scss"; 

//

// CONFIG -- site specific (color overwite, button overwrite, custom mixins etc)
@import "config/_z-index.scss";
@import "config/_colors-config.scss";
@import "config/_typography.scss";
@import "config/_bootstrap.scss";
// @import "plugins/bootstrap/_bootstrap.scss";
// @import "config/_colors-config.scss";
// @import "config/_links-btns-config.scss";
// @import "config/_forms-config.scss";
// @import "config/_table-config.scss";
// @import "config/_typography.scss"; //the override for the compass reset...
// @import "config/_custom-icons.scss";
// @import "config/_cookie-bar-theme.scss";


// TEMPLATES
//@import "template/_colours-example.scss";


// FOUNDATIONS -- all of the foundations (things that create the basic structure, header, footer, nav, subnav -- all of these types of things will be needed, the content of these files will be different tho)
// @import "foundations/_errorHandling.scss";
@import "foundations/_globals.scss";
@import "foundations/_header.scss";
@import "foundations/_footer.scss";
@import "foundations/_rightcol.scss";
@import "foundations/_topic.scss";
// @import "foundations/_mobile-nav.scss"; 
// @import "foundations/_subnav.scss"; 
// @import "foundations/_fixedHeader.scss";
// @import "foundations/_footer.scss";
// @import "foundations/_links_buttons.scss";
// @import "foundations/_globals.scss";
// @import "foundations/_select-and-dropdowns.scss";
// @import "foundations/_top-drawer.scss";
// @import "foundations/_forms.scss";
// @import "foundations/_tabular-data.scss";
// @import "foundations/_alerts.scss"; 
// @import "foundations/_modal.scss";
// @import "foundations/_grid-extended.scss";
// @import "foundations/_mobile-navigation.scss";

// MODULES -- site specific modules (trio modules, programme planner, spikes nights)



@import "modules/module-blocks/_homeslider-block.scss";
@import "modules/module-blocks/_recent-block.scss";
@import "modules/module-blocks/_cookies-block.scss";
// @import "modules/module-blocks/_login-side-box.scss";
// @import "modules/module-blocks/_booking-process.scss";
// @import "modules/module-blocks/_process-breadcrumb.scss";
// @import "modules/module-blocks/_accommodation-nav.scss";
// @import "modules/module-blocks/_accommodation-list.scss";
// @import "modules/module-blocks/_accommodation-note.scss";
// @import "modules/module-blocks/_accommodation-detail.scss";
// @import "modules/module-blocks/_contact-category.scss";
// @import "modules/module-blocks/_accordian.scss";
// @import "modules/module-blocks/_modules-general.scss";
// @import "modules/module-blocks/_block-module.scss";
// @import "modules/module-blocks/_image-switcher.scss";
// @import "modules/module-blocks/_steps.scss";
// @import "modules/module-blocks/_search.scss";
// @import "modules/module-blocks/_account-overview.scss";
// @import "modules/module-blocks/_home-winners-shortlist.scss";
// @import "modules/module-blocks/_section-menu.scss";
// @import "modules/module-blocks/_browse-by-menu.scss";
// @import "modules/module-blocks/_companies-listing.scss";
// @import "modules/module-blocks/_twitter-module.scss";
// @import "modules/module-blocks/_dates-block.scss";
// @import "modules/module-blocks/_home-modules.scss";
// @import "modules/module-blocks/_pass-picker.scss";
// @import "modules/module-blocks/_modal-box.scss";
// @import "modules/module-blocks/_programme-grid.scss";
// @import "modules/module-blocks/_pass-picker--delegates.scss";



// module-sections -- should really be configs of the existing building block modules (above) for specific differences

@import "modules/module-sections/_page-section.scss";
@import "modules/module-sections/_contactus-section.scss";
@import "modules/module-sections/_companyinformation-section.scss";
@import "modules/module-sections/_sitemap-section.scss";
@import "modules/module-sections/_blog-section.scss";
@import "modules/module-sections/_clients-section.scss";
@import "modules/module-sections/_search-section.scss";
@import "modules/module-sections/_vacancies-section.scss";
@import "modules/module-sections/_history-section.scss";
// BASICS -- things that will be on every site (color, buttons, forms, reset, conditionals, global mixins - can be overwritten in styles)

@import "basic/_helpers.scss";
@import "basic/_print.scss";