.topic{
	background-color:$blue-lightest;
	border-top:1px solid $grey-lightest;
	border-bottom:1px solid $grey-lightest;
	@include ie(8){
		display:block; 
		float:left;
		width:100%;
	}
	.description{
		min-height: 85px;
		display: table;

		&--content{
			display: table-cell;
			vertical-align: middle;
			padding: 15px 0;

			@include media(mobile){
				padding-top:0;
			}
		}
		p{
			line-height:1.3em;
			margin-bottom:0;
		}		
	}//description
}//topic