.clients{
	.logos{
		padding-top:30px;
		padding-left:0;
		padding-right:0;

		.client-logo{
			text-align: center;
			border:1px solid $grey-lightest;
			margin:0 0 20px;
			@include box-sizing(border-box);
			display: block;
			width: 100%;
			background-color: $color-white;
			padding:20px;

			&:hover{
				border-color:$blue;
			}
		}//client-logo
	}//logos

	.other-client{
		text-align: center;
		border:1px solid $grey-lightest;
		margin:0 0 5px;
		padding: 10px;
		display: block;
		@include box-sizing(border-box);
		font-weight:600;
	}//other-client

	.image-tags{
		display:block;
		width:100%;
		float:left;
		margin-bottom:20px;
		background-color:$main-gradient1;
		padding:20px;
		@include box-sizing(border-box);

		@include media(mobile){
			padding:10px;
		}

		@include ie-lte(7){
			padding:20px 0;
		}

		h2{
			margin-top:0;
		}

		img{
			float:left;
			margin-right:20px;

			@include media(mobile){
				float:none;
				margin:0 0 20px;
				width:100%;
			}
		}//img
	}//image-tags
	
	.site-link{
		margin-top:3px;
		img{
			@include border-radius(20px);
			&:hover{
				opacity:0.85;
			}
		}
		&.bottom{
			margin:10px 0;
		}
	}//site-link

	div.quote{
		width:100%;
		float:left;
		background-color:$main-gradient1;
		position: relative;
		@include box-sizing(border-box);
		

		.case-study-quote{
			display: table;
			min-height:70px;
			padding:20px;
			@include media(mobile){
				padding:10px;
			}

			@include ie-lte(7){
				padding:0 0 20px;
			}
			.fa{
				font-size:5em;
				color:$color-white;
				position:absolute;
				z-index: 100;

				&.fa-quote-right{
					bottom:10px;
					right:10px;
				}
				&.fa-quote-left{
					top:10px;
					left:10px;
				}
			}

			p{
				position:relative;
				z-index: 1000;
				display: table-cell;
				vertical-align: middle;
				font-weight: 200;
				font-style:italic;
				font-size:1.2em;
				color:$grey-light;
			}
		}//case-study-quote

		.case-study-quote-attributed{
			padding:0 20px 20px;
			color:$grey;

			@include media(mobile){
				padding:0 10px 10px;
			}

			@include ie-lte(7){
				padding:0 0 20px;
			}
				span{
					padding:0 10px;
					& + span{
						border-left:1px solid $grey-lightest;
					}
				} 
			}//case-study-quote-attributed
	}//quota
}//clients