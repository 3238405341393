#recentwork{
	margin-top: 10px;
	margin-left: 20px;
	overflow-x: hidden;
	height: auto;
	overflow-y: hidden;
	


	@include media($max-width:1400px){
		width: 1200px;

	
	}

	@include media($max-width:1240px){
		width: 1000px;
	
	}


	@include media($max-width:1020px){
		width: 800px;
	
	}


	@include media($max-width:8px){
		width: 600px;
	
	}
	
	@include media(mobile){
		margin-top: -5px;
}

}


#worknext{
	
	@include media($max-width:767px){
	position: relative;
	top: -300px;
	left: 170px;
	font-size: 26px;

}

}


.recent-block {

	

	float: left;
	@include media($min-width:767px){
	margin-top: -42px;
	}




	.list-item {
		padding: 6px 15px 8px;
		display: inline-block;
		background-color: #fff;
			font-size: 1.2em;
		color: #000;
		position: relative;
		width: 170px;
		// bottom: -274px;
		vertical-align: top;

		@include media(mobile) {
			display: inline-block;
			width: 130px;
			font-size: 14px
		}

		.name {
			
			line-height: 1.3em;
		
		
		}

		span {
			display: block;
			clear: both;
			color: $blue;
			margin-top: 3px;
			font-weight: 200;
			font-size: 0.85em;

			.fa {
				margin-right: 3px;
				color: $grey-light;
				display: none;
			}
		}

		&:hover {
			background-color: $blue;
			color: $color-white;

			span {
				.fa {
					color: $blue-light;
				}

				color:$blue-light !important;
			}
		}
	}

	//list-item


}

//recent-block

// line between the home slider and recently launched

html {
	 overflow-x: hidden;
}

.empty {
	position: relative;
	top: 2rem;
	margin: 0px -26rem;
	padding: 1px 21px;
	// padding: 2.25px 1.5rem;
	// padding: 0.25rem 1.5rem;
	background: #00B5EC;
	// border-left: 1px solid #00B5EC; 
	// border-right: 1px solid #00B5EC; 
	width: 134rem;
	// border-radius: 2px;
	// box-shadow: 0px 1px 10px #999;
	background: linear-gradient(to right, black, #00b5ec);
}

/* min-height: 100%
overflow-x: hidden */