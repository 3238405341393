    /* ==========================================================================
       Non-semantic helper classes: please define your styles before this section.
       ========================================================================== */

    /* For image replacement */
    .ir { display: block; text-indent: -999em; overflow: hidden; background-repeat: no-repeat; text-align: left; direction: ltr; }

    /* Hide for both screenreaders and browsers:
       css-discuss.incutio.com/wiki/Screenreader_Visibility */
    .hidden { display: none; visibility: hidden; }

    /* Hide only visually, but have it available for screenreaders: by Jon Neal.
      www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden */
    .visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
    /* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638 */
    .visuallyhidden.focusable:active,
    .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

    /* Hide visually and from screenreaders, but maintain layout */
    .invisible { visibility: hidden; }

    /* The Magnificent Clearfix: Updated to prevent margin-collapsing on child elements in most situations.
       nicolasgallagher.com/micro-clearfix-hack/ */
    .clearfix:before, .clearfix:after { content: ""; display: table; }
    .clearfix:after { clear: both; }
    .clearfix { zoom: 1; }