// http://www.color-blindness.com/color-name-hue/

// names colors
$color-white: #ffffff;
$color-white-smoke: #eee;
$color-gainsboro: #ddd;
$color-light-sand:#f0e4c8;
$color-very-light-grey: #ccc;
$color-silver: #bbb;
$color-dark-grey: #aaa;
$color-nobel: #999;
$color-suva-grey: #888;
$color-grey: #777;
$color-dim-grey: #666;
$color-mortar: #555;
$color-charcoal: #444;
$color-night-rider: #333;
$color-nero: #222;
$color-almost-black: #111;
$color-blue-light:#e5edf8;
$color-black: #000000;
