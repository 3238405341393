// $main-font: $futura-book-latin;
// $main-font-medium: $futura-medium-latin;
// $main-font-bold: $futura-heavy-latin;
// $main-font-italic: $futura-book-oblique-latin;

$main-font: 'Poppins', sans-serif;
$main-font-condensed: 'Poppins', sans-serif;

$headings-font: $main-font;
$headings-font-medium: $main-font;
$headings-font-semibold : $main-font;
$headings-font-bold : $main-font;

@mixin font($style){
    @if $style == light{
        font-style: normal;
        font-weight: 300;
    }   
    @else if $style == italiclight{
        font-style: italic;
        font-weight: 300;
    }
    @else if $style == normal{
        font-style:normal;
    }
    @else if $style == italic{
        font-style: italic;
    }
    @else if $style == semibold{
        font-style: normal;
        font-weight: 600;
    }
    @else if $style == italicsemibold{
        font-style: italic;
        font-weight: 600;
    }
    @else if $style == bold {
        font-style: normal;
        font-weight: 700;
    }
    @else if $style == italicbold{
        font-style:italic;
        font-weight: 700;
    }
    @else if $style == extrabold{
        font-style: normal;
        font-weight: 800;
    }
    @else if $style == italicextrabold{
        font-style:italic;
        font-weight: 800;
    }
}
 

// .main-font-light { @include font(light); }
// .main-font-italic-light { @include font(italiclight); }
// .main-font-normal{@include font(normal);}
// .main-font-italic{@include font(italic);}
// .main-font-semibold{@include font(semibold);}
// .main-font-italic-semibold{@include font(italicsemibold);}
// .main-font-bold{@include font(bold);}
// .main-font-italic-bold{@include font(italicbold);}
// .main-font-extrabold{@include font(extrabold);}
// .main-font-italic-extrabold{@include font(italicextrabold);}
// .main-font-condensed-light{font-family: $main-font-condensed; @include font(light)};
// .main-font-condensed-italic-light{font-family: $main-font-condensed; @include font(italiclight)};
// .main-font-condensed-bold{font-family: $main-font-condensed; @include font(bold)};

p{
    line-height:1.3em;
    margin-bottom:10px;
}

h1{
    font-size: 30px;
    margin:0.45em 0 0.5em 25px;
    padding:0;
    font-weight: medium;
}

h2{
    font-size:18px;
    margin:0.9em 0;
    padding:0;
}

h3{
    font-size:1.5em;
    margin:1em 0;
    padding:0;
}

h4{
    font-size:1.2em;
    margin:1em 0;
    padding:0;
}

h5{
    font-size:1em;
    margin:1em 0;
    padding:0;
}

h6{
    font-size:0.85em;
    margin:1em 0;
    text-transform: uppercase;
    padding:0;
}


h1, h2, h3, h4, h5, h6 
{   
    font-family: $main-font-condensed;
    color:$blue;
    .grey,&.grey{color:$grey;} 
    .dark,&.dark{color:$dark};

    &.cl{
        font-weight:  500;
        font-family: $main-font-condensed;
    }
    &.cb{
        font-weight:  600;
        font-family: $main-font-condensed;
    }

    .btn{
        font-family:$main-font;
        font-weight: normal;
    }

}

ul{
    li{ 
        margin-bottom: 7px;
    }
}

strong,b{
    font-weight:600;
}