
#team{

	p, h2{
	padding-left: 30px;
	@include media($max-width:767px){
		//margin-left: inherit;
	}


}


	.pics{
		float:left;
		margin: 10px 15px 0px 10px;
		
	}

	img{
	border-radius: 50%;
	max-width: 250px;
	}

	.names{
	text-align: center;
	padding-top: 10px;
	padding-left: 0px;
	}

}

.page{
	padding-top:30px;
	padding-bottom:30px;


	@include media(mobile){
		padding-top:5px;
		padding-bottom:5px;
		padding-left: 10px;
	padding-right: 10px;
	}

	h1,h2,h3,h4,h5,h6{
		&:first-child{
			margin-top:0;
		}
	}

	table{
		border-spacing: 2px;

		th{
			background: $blue;
			color:$color-white;
			padding:15px 5px;
		}

		td{
			background: $blue-light;
			padding:10px 5px;
		}

		th,td{
			@include media(mobile){
				padding:5px;
			}
		}
	}//table
}//page

.tags{
		li{
			display: inline-block;
			margin-bottom: 5px;

			@include ie-lte(7){
				display:block;
				float:left;
				margin-right:4px;
			}
		}
	
		.tag-item{
			background-color:$blue;
			color:$color-white;
			padding:5px 15px;
			margin-right:2px;
			display:block;
			&:hover{
				background-color:$dark;
			}
		}//tag-item
	}//tags

.related-tags{
	margin:20px 0;
	span{
		font-weight: 600;
	}

	a{
		font-weight:200;
		font-style: italic;
		&:hover{
			text-decoration: underline;
		}
	}
}//related-tags
