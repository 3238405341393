.blog{
	
	.blog-content{
		border-bottom:2px solid $blue;
	}//blog-content

	.post-top{
		h2{
			margin-bottom:5px;
			.fa{
				color:$blue;
			}
			span{
				@include media(mobile){
				float:left;
				display: block;
				width:100%;
				margin:10px 0;
				}
			}
		}

		h4{
			margin-top:0;
			margin-bottom:20px;
			color:$blue;	
		}
	}

	.blog-links{
		text-align:center;
		border-top:1px solid $grey-lightest;
		padding-top:10px;
		padding-bottom:10px;
		width:100%;
		float:left;
		a{ 
			display: inline-block;
			vertical-align: middle;
			padding:0 10px;

			& + a{
				border-left:1px solid $grey-lightest;	
			}

			&:hover{
				color:$dark;
			}
		}
	}//blog-links

	.author{
		margin:15px 0;
		color:$grey;
		font-style: italic;
		font-weight:200;
		.fa{
			color:$dark;
		}
	}//author
	.rightcol{
		padding-bottom:20px;
		.item-count{
			font-weight: 200;
			font-size:0.85em;
		}
		ul.right-menu{
			padding:0;
		}

		li:hover{
			background-color:$blue;
			color:$color-white;
			span{
					color:$blue-light;
				}
		}

		span.item--date{
			display:block;
			clear:both;
			color:$grey-light;
			margin-top:3px;
			font-weight:200;
			font-size:0.85em;

			.fa{
				margin-right:3px;
				color:$grey-light;
			}		
		}//item--date

		.btn.btn-primary{
			color:$color-white;
		}
	}//rightcol
	
	.comments-container{
		.comment{
			display: block;
			width:100%;
			float:left;

		   & + .comment{
		   		border-top:1px solid $grey-lightest;
		   }
		 }
		.comment-date{
		   	 margin:15px 0;
		   	 color:$grey;
		   }

		 .content{
		 	margin-top:15px;
		 	color:$grey;
		 }
		 .comment-date,.comment-author{
		 	margin:5px 0;
		 	p{
		 		margin:0;
		 	}
		 }

		 .row{
		 	width:100%;
		 	float:left;
		 	display: block;

		 	label{
		 		text-align: right;
				padding: 7px 10px;
				line-height: 1.3em;
		 	}

		 	img{
		 		margin-bottom:10px;
		 	}

		 	.grid-70,.grid-100{
		 		padding-left:0;
		 		padding-right:0;
		 	}
		 	.btn{
		 		margin-bottom:20px;
		 	}
		 }//row
	}//comment-container
}//blog