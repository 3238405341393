// toolbox

@mixin coverer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  @include translate(-50%, -50%);
}
@mixin word-wrap() {
  word-break:     break-word;
  -webkit-hyphens: auto;
  -moz-hyphens:    auto;
  hyphens:         auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@function black($opacity) {
  @return rgba(black, $opacity)
}
@function white($opacity) {
  @return rgba(white, $opacity)
}

// CUSTOM MIXINS

// example: input { @include placeholder { /* styles for placeholder here */ } }
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        @content;
    }
}

// example: @include list($horizontal: true);
@mixin list($horizontal: false, $vertical: false, $centered: false) {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    @if $horizontal { display: block;}
    @if $centered { display: inline-block;}
    @if $vertical { display: block; }
    li{
        display: block;
        @if $horizontal { float: left}
        @if $centered { float: left}
        @if $vertical { float: none; }
        margin: 0;
        &:last-child{
            @if $horizontal { margin-right: 0px; }
            @if $centered { margin-bottom: 0px; }
            @if $vertical { margin-right: 0px; }
        }
    }
}

// example: @include boxSizing(border-box);
@mixin boxSizing($sizing) {
    -moz-box-sizing: $sizing; 
    -webkit-box-sizing: $sizing; 
    box-sizing: $sizing; 
    *behavior: url(/assets/javascripts/boxsizing.htc);   //ie7 polyfill
}
// example: @include translatex(0);
@mixin translatex($width) {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translatex($width);
    -moz-transform: translatex($width);
    -o-transform: translatex($width);
    transform: translatex($width);
}
//example: @include background-opacity(#333, 0.5);
@mixin background-opacity($color, $opacity) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

//example: width: percentage-it(2); 
// -- > width: 50%;
@function percentage-it($number){
    @return (100%/$number);
}

//example: @include sections($section-name: help, $section-items: 4)
@mixin sections($section-name, $section-items) {
    &.section-#{$section-name} {
        .grid
        {
            width: percentage-it($section-items);
        }
    }
}
//example @include side-border($side:top, $width:1px, $color:#fff);
@mixin side-border($side:top, $width:1px, $color:#fff)
{
    border-#{$side}: #{$width} solid #{$color};

}

//example
// @include keyframes(move-the-object) {
//   0%   {  @include boxRotate(0deg); }
//   100% {  @include boxRotate(360deg); }
// }

// .test-spinner{
//     width: 40px;
//     height: 40px;
//   @include animation('move-the-object 2s infinite');
// } 

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}
/*
 * Apply a CSS3 border radius
 * @include borderRadius(4px);
 */
@mixin borderRadius($radius: 4px) {
    -webkit-border-radius: $radius;
            border-radius: $radius;
    -moz-background-clip: padding; -webkit-background-clip: padding-box; background-clip: padding-box;
}

// example: @include transition(all, 1s, ease-in-out);
@mixin transition($property, $duration, $function) {
  -webkit-transition: ($property $duration $function);
     -moz-transition: ($property $duration $function);
      -ms-transition: ($property $duration $function);
       -o-transition: ($property $duration $function);
          transition: ($property $duration $function);
}