.history{
	
	ul#years{
		margin-top:20px;
		padding:0 44px;
		position: relative;
		@include boxSizing(border-box);

		li{
			display: block;
			margin-bottom:4px;

			@include ie-lte(7){
				display:block;
				float:left;
				margin-right: 4px;
			}

			.btn{
				display: block;
				margin:0 2px;
			}
		}

		.owl-prev{
			position:absolute;
			left:0px;
			top: 0px;			
			a {
				font-size:19px;	
			}
		}

		.owl-next{
			position:absolute;
			right:0;
			top:0;
			a {
				font-size:19px;	
			}
		}
	}

	.history-full-width{
		.owl-buttons{
			margin-bottom:5px;

			@include ie-lte(8){
				display:none;
			}

			.owl-next,
			.owl-prev{
				display:inline-block;
			}
			.btn{
				display: inline-block;
				margin:0 5px 5px 0;
			}
			.fa{
				font-size:1.5em;
			}
		}//owl-buttons
	}
}//history