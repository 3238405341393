.company-information{
	.data-blocks{
		margin:50px 0;
		float:left;
		width:100%;
		@include media(mobile){
			margin:20px 0;
		}
		.data-info{
			padding:20px;
			margin:10px 0 10px;
			color:$color-white;
			//background-color: $blue-light;
			border-top:2px solid $blue;
			border-bottom:1px solid $grey-lightest;
			height:110px;

			h4{
				margin:0 0 10px;
				color:$grey;
			}
			p{
				color:$dark;
			}
			.fa{
				font-size:6.5em;
				color:$grey-lightest;
				float:left;
				margin-right:20px;

				@include media(mobile){
					width: 110px;
					text-align: center;
				}
			}
			&.blue{
				.fa{
					color:$dark;
				}
			}
		}//data-info
	}//data-blocks
}//company-information