body,
html {
	background-color: $color-white; //$color-sublte-grey
	margin: 0;
	height: 100%; // so that we canhave full height sections
	font-family: $main-font;
	font-size: 14px;
	overflow-x: hidden;
}

div.main {
	width: 100%;
	float: left;
	display: block;
	background: white;
	
	// background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI1JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNGY0ZjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	// background: -moz-linear-gradient(top,  $main-gradient1 0%, $main-gradient2 15%, $main-gradient2 85%, $main-gradient1 100%); /* FF3.6+ */
	// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$main-gradient1), color-stop(15%,$main-gradient2), color-stop(85%,$main-gradient2), color-stop(100%,$main-gradient1)); /* Chrome,Safari4+ */
	// background: -webkit-linear-gradient(top,  $main-gradient1 0%,$main-gradient2 15%,$main-gradient2 85%,$main-gradient1 100%); /* Chrome10+,Safari5.1+ */
	// background: -o-linear-gradient(top,  $main-gradient1 0%,$main-gradient2 15%,$main-gradient2 85%,$main-gradient1 100%); /* Opera 11.10+ */
	// background: -ms-linear-gradient(top,  $main-gradient1 0%,$main-gradient2 15%,$main-gradient2 85%,$main-gradient1 100%); /* IE10+ */
	// background: linear-gradient(to bottom,  $main-gradient1 0%,$main-gradient2 15%,$main-gradient2 85%,$main-gradient1 100%); /* W3C */
	//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$main-gradient1', endColorstr='$main-gradient1',GradientType=0 ); /* IE6-8 */

	@include ie-lte(8) {
		background: $main-gradient1 !important;
	}
}

//get rid of the borders on the homepage panel

.grid-100 {
	padding-left: 0;
	padding-right: 0;
}

//main






a:link,
a:visited {
	text-decoration: none;
	color: $link-color;
}

hr {
	background: transparent;
	border-top: 1px solid $grey;
}

img {
	max-width: 100%;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
button:focus,
textarea:focus {
	outline: none !important;
	border-color: $blue;
	background-color: $blue-lightest;
	color: $dark;
}

button,
input[type='submit'] {
	-webkit-appearance: none;
}

button,
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
	margin-bottom: 10px;
	background-color: $color-white;
	font-style: italic;
	font-weight: 100;
	font-family: 'Open Sans', sans-serif;
}

.captcha-image {
	img {
		max-width: 100%;
		opacity: 0.5;
		filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/s….3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
		-moz-filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		filter: gray;
	}
}



.grid-container {
	max-width: 1600px;
	width: 100%;
	
	
	



	
	@include media(mobile) {
		
		
	}



	@include media($max-width:767px){
		margin: 0;
		padding: 0;
		
	}

	@include ie-lte(8) {
		max-width: 960px;
		width: 960px;
		display: block;
		margin: auto;

		.grid-10 {
			width: 10%;
		}

		.grid-20 {
			width: 20%;
		}

		.grid-30 {
			width: 30%;
		}

		.grid-33 {
			width: 33%;
		}

		.grdi-50 {
			width: 50%;
		}

		.grid-66 {
			width: 66%;
		}

		.grid-80 {
			width: 80%;
		}

		.grid-70 {
			width: 70%;
		}

		.grid-90 {
			width: 90%;
		}

		.grid-100 {
			width: 100%;
		}

		.grid-10,
		.grid-20,
		.grid-30,
		.grid-33,
		.grid-50,
		.grid-66,
		.grid-80,
		.grid-70,
		.grid-90,
		.grid-100 {
			float: left;
		}
	}
}

.fa {
	@include ie-lte(7) {
		display: none;
	}
}