#recently-launched {
	position: relative;
	left: 14rem;
	


	@include media($max-width:767px) {
		left: 0;
		bottom: 0;
		padding: 0;
	}
}

#studies {
	background: #D9F2F9;
}

#select-case {
	color: #000;
	padding: 10px;
	font-size: 24px;
	font-weight: 800;
	background: #D9F2F9;
}

.rightcol {
	background: transparent;
	padding-bottom: 1px;
	// display: inline-flex;

	@include media($max-width:767px) {
		float: left;
		width: 100%;
		padding-bottom: 15px;
	}

	@include media(mobile) {
		padding-bottom: 0;
	}

	@include ie-lte(8) {
		margin-left: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}

	h2+.right-menu {
		padding-top: 0;
	}

	.right-menu {
		padding: 20px 0 0;

		&.mobile {
			@include media(mobile) {
				display: none;
			}
		}

		li a {
			padding: 12px 10px;
			display: block;
			background-color: #D9F2F9;
			margin-bottom: 3px;
			font-size: 1.1em;
			color: $grey-dark;

			.fa {
				color: $blue-light;
			}

			&.nav_link_on {
				background-color: $blue;
				color: $grey-dark !important;

				span,
				.fa {
					color: $grey-dark !important;
				}
			}

			&:hover {
				color: $blue;

				span,
				.fa {
					color: $blue;
				}
			}
		}

		li ul {
			margin-top: 7px;
			margin-left: 7px;
		}

		// & + h2{
		// 	//margin-top:0px;
		// }
	}

	//right-menu
	.owl-buttons {
		margin-bottom: 8px;

		.owl-prev {
			display: none;
		}

		.owl-next {
			margin: 5px 8px 0 0;
			font-size: 2em;
			// color:$blue;
			font-weight: normal;
			position: absolute;
			left: -6rem;
			top: 3rem;

			@media(max-width: 767px) {
				left: 0;
				top: 18rem;
			}

			.btn {
				padding: 3px 10px;

				&:hover {
					background-color: $dark;
					border-color: $dark;
				}
			}

			.owl-next,
			.fa {
				font-size: 2.5em;
				color: grey;
				position: relative;
				left: 1rem;
				top: -17px;
			

			}

			.right-arrow {

				background: transparent !important;
				border-color: transparent !important;
				
				box-shadow: 0 !important;

				&:active {
				
					box-shadow: 0 !important;
					
				}
			}
		}

		@include ie-lte(7) {
			display: none;
		}
	}

	//owl-buttons

	.page-img {
		width: 100%;
		margin-bottom: 20px;
	}

	.tags {
		margin-bottom: 20px;
	}

	.other-link {
		a.link {
			display: none;
			// display: block;
			color: $grey-dark;
			float: left;
			width: 100%;
			clear: both;
			padding: 6px 10px;
			background-color: $color-white;
			margin-bottom: 3px;
			font-size: 1.1em;
			cursor: pointer;
			//font-weight: 200;
			@include boxSizing(border-box);

			@include ie-lte(8) {
				padding: 6px 0;
			}

			.fa {
				display: none;
				// display: block;
				float: left;
				font-size: 2em;
				margin-right: 10px;
				color: $grey-lightest;
				width: 30px;
				text-align: center;

				@include ie-lte(7) {
					display: none;
				}

				@include ie(8) {
					margin-left: 10px;
				}
			}

			span {
				display: none;
				// display:block;
				float: left;
				padding: 8px 0;

				@include ie-lte(8) {
					margin-left: 10px;
				}
			}

			&:hover {
				background-color: $blue;
				color: $color-white;

				.fa {
					color: $blue-light;
				}
			}
		}

		//link

		&.awards-engine {
			@include media(mobile) {
				margin-bottom: 20px;
				width: 100%;
				float: left;
			}
		}

		//awards-engine
	}

	//other-link
}

//rightcol

.mobile-menu {
	display: none;

	@include media(mobile) {
		display: block;
		padding: 10px 10px 0;
	}
}