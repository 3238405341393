.history{

#years, .owl-wrapper-outer {
	max-width: 1000px;

	@include media($max-width:1400px){
		max-width: 800px;
	}

	@include media($max-width:1200px){
		max-width: 800px;
	}


	@include media($max-width:1000px){
		max-width: 600px;
	}

	@include media($max-width:800px){
		max-width: 300px;
	}


	@include media($max-width:600px){
		max-width: 300px;
	}

	@include media(mobile){
		max-width: 350px;
	}

}

.owl-wrapper-outer, .history-panel {
	max-width: 940px;

	@include media($max-width:1400px){
		max-width: 740px;
	}

	@include media($max-width:1200px){
		max-width: 740px;
	}


	@include media($max-width:1000px){
		max-width: 540px;
	}

	@include media($max-width:800px){
		max-width: 240px;
	}


	@include media($max-width:600px){
		max-width: 300px;
	}

	@include media(mobile){
		max-width: 350px;
	}

}







	
}




header{
	
	position:relative;
	border-radius: 2px;
    box-shadow: 0px 1px 10px #999;
	@include ie(8){
		float:left;
		width:100%;
	}

	@include media(mobile){
		padding-bottom:0px;
	}

	.logo{

		max-width: 200px;
		height: auto;
		
		margin: 5px 0 5px 25px;
	


		@include media($max-width:1100px){
			
			margin: 5px 0 0 25px;
			
	
			
		}

		@include media(mobile){
			text-align: center;
			max-width: 45%;
			margin: auto;
			
			
		}
	}


	.top{

	
		height: auto;
		margin: 0 !important;
		padding: 0;


		@include media($max-width:1100px){


		}


		@include media($max-width:776px){
			
							
			margin: auto;

			
		
			
		}

		@include media(mobile){
			text-align: center;
			
		}
	}
	.main-menu{
		@include media($max-width:1200px){
		
			
			
		}
	}//main-menu

	#navigation_list{
		float:right;
		display:block;
	

		
		margin-top: 39px;
		margin-right: 10px;

		

		@include media($max-width:1200px){

	
		}

		@include media($max-width:767px){
		
			display:none;
			
		}

		@include media(mobile){
			display:none;
			float:none;
		
		}

		li{
			display: inline-block;
			position:relative;
			margin-bottom:0;
						

			@include media(mobile){
				display:block;
				width:100%;
			}

			a{
				padding:5px 10px 15px;
				display:block;
				font-size: 15px;

				&.nav_link_on{
					color:$dark;
					@include media(mobile){
						color:$color-white;
					}
				}
				@include media($max-width:830px){
					padding:5px 4px 15px 0;
				}

				@include media(mobile){
					padding:15px 0 15px 15px;
					border-bottom:1px solid $grey-lightest;
					background-color:$blue;
					color:$color-white;
					&:hover{color:$color-white !important;}
				}
			}

			&:hover{
				a{
					color:$dark;
					@include media(mobile){
						color:$color-white;
					}
				}

				ul{
					display:block;
					position:absolute;
					top:100%;
					left:0;	
					@include media(mobile){
						display:none;
					}
				}
			}//:hover
		}

		ul{
			display:none;
			text-align: left;
			width:180px;
			padding:0 10px 10px;
			background-color:$color-white;
			border:1px solid $grey-lightest;
			border-top:0;
			z-index:100000000;

			li{
				display:block;
			}

			a{
				padding:5px 10px 5px 0;
				border-bottom:1px solid $grey-lightest;
				color:$grey;
				line-height:1.25em;
				&:hover{
					color:$blue;
					@include media(mobile){
						color:$color-white;
					}
				}

				&.subnav_link_on{
					color:$blue;
					@include media(mobile){
						color:$color-white;
					}
				}

				@include media(mobile)
				{
					padding:15px 0 15px 25px;
					position:relative;
					//border-color:$dark;
					color:$color-white;
					&:hover{
						color:$color-white !important;
					}

					&::before{	
						content: '';
						display: block;
						position: absolute;
						left: 12px;
						top: 17px;
						padding:6px 10px;
						border-left: 1px solid $blue-light;
						border-bottom: 1px solid $blue-light;
						width: 5px;
						height:7px;
					}
				}		
			}
		}//ul inside li
	}//navigation_list

	.mobile-menu{
		display:none;
		
			@include media($max-width:767px){
			display:block;
			position:absolute;
			right:20px;
			top: 32px;
			// top:12px;
			padding:6px 10px;
			color:$blue;
			cursor:pointer;
			&:hover{
				color:$dark;
			}

		}

		@include media(mobile){
				display:block;
				position:absolute;
				right:20px;
				top: 25%;
				// top:12px;
				padding:6px 10px;
				color:$blue;
				cursor:pointer;
				&:hover{
					color:$dark;
				}
			}
		
	}

}//header


#site-search-form{
	    visibility: hidden;
		float:right;
		width:250px;
		clear:both;
		margin:15px 0 0;
		height:60px;
		position: relative;
		@include media($max-width:767px){
			margin:10px auto;
			float:none;
			height:initial;
		}
		@include media(mobile){
			width: 100%;			
			margin-bottom:0;
		}

		@include ie-lte(8){
			width:250px;
			float:right;
			margin-top:20px;
		}

		input#keywords{
			@include border-radius(17px);
			border:2px solid $blue;
			margin-bottom:5px; 

			@include ie-lte(8){
				height:20px;
				border:1px solid $grey-lightest;
			}
		}

		input.submit{
			position: absolute;
			top: 4px;
			right: 5px;
			border-radius: 20px;
			padding: 0 6px;
			height:26px;
			line-height:23px;

			@include ie-lte(8){
				width:40px;
				text-align: center;
				padding:0;
				right:-22px;
				top:5px;
				background-color:$dark;
				border-color:$dark;
			}
		}

		#search_msg{
			color:$red;
			font-size:0.85em;
			text-align:center;
		}
	}//site-search-form
//---------------------------------------------------------------------- IE css
.main-menu{
	@include ie-lte(8){
			width:100% !important;
		}
}

#navigation_list{
	@include ie-lte(8){
		width:100%;
		& > li{
			display:block;
			float:left;
			position:relative;
			margin-bottom:0;
			&:hover > a{
					color:$dark;
				}

			& > a{
				display:block;
				padding:10px 10px 15px;

			}

			&:hover > ul{
				display:block;
				position:absolute;
				top:100%;
				left:0;
				background-color:$color-white;
				border:1px solid $grey-lightest;
				border-top:0;
				width:250px;
				padding:0 10px 10px;
			}

			ul{
				display:none;
			}
			li{
				float:none;

				& > a{
					display: block;
					padding:5px 0;
					border-bottom:1px solid $grey-lightest;

					&:hover{
						color:$dark;
					}
				}
			}
		}
	}
}//navigation_list