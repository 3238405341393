.search{
	padding-bottom:30px;

	.search-result-row{
		padding:10px 0;
		width:100%;
		float:left;
		display:block;
		border-bottom:1px solid $grey-lightest;

		.btn{
			float:left;
			margin-right:10px;
		}

		p{
			float:left;
			color:$grey-light;
			font-style:italic;
			margin:6px 0 0;

			span.first{
				color:$grey-lightest;
			}
			span.last{
				color:$grey;
			}
		}
	}//search-result-row
}//search