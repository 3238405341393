/*======================================== main colors in templates*/
$blue:#00B5EC;
$blue-light:#D9F2F9;
$blue-lightest:#F7FCFF;


$grey-dark:#333333;
$grey:#777777;
$grey-light:#999999;
$grey-lightest:#E3E5E4;

$dark:#111111;

/*======================================== info - alerts */
$red:#FF3F3F;
$red-light:#FFAFAF;

/*======================================== links - buttons */
$link-color:#00B5EC;

/*======================================== main content div.main */
$main-gradient1:#f4f4f4;
$main-gradient2:#ffffff;