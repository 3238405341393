// Media query helpers. These make producing IE layouts
// super easy.

// The base css you write should be for mobile. You can
// then add desktop styles on top.
//
// Usage:
//
//   div.columns {
//     border: 1px solid;
//     width:100%;
//
//     @include media(desktop){
//       width: 30%;
//       float: left;
//     }

//     @include ie-lte(8) {
//       something to fix visual bugs in old IE
//     }
//     @include ie(6) {
//       padding: 0;
//     }
//   }


$is-ie: false !default;
$mobile-ie6: true !default;
@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@mixin media($size: false, $max-width: false, $min-width: false, $ignore-for-ie: false) {

    //convert pixel break points in to ems
    //automatically convert pixels to em's
    //$size: pem($size, $base: 16);//

    @if $is-ie {
        @if ($ignore-for-ie == false) {
            @if $size != mobile {
                @if ($ie-version == 6 and $mobile-ie6 == false) or $ie-version > 6 {
                    @content;
                }
            }
        }
    } @else {
        @if $size == desktop {
            @media (min-width: 48.063em){  //769px
                @content;
            }
        } @else if $size == tablet {
            @media (min-width: 40.063em){ //641px
                @content;
            }
        } @else if $size == mobile {    
            @media (max-width: 40.000em){ //640px
                @content;
            }
        } @else if $max-width != false {
            @media (max-width: $max-width){
                @content;
            }
        } @else if $min-width != false {
            @media (min-width: $min-width){
                @content;
            }
        } @else {
            @media (min-width: $size){
                @content
            }
        }
    }
}

@mixin ie-lte($version) {
    @if $is-ie {
        @if $ie-version <= $version {
            @content;
        }
    }
}
@mixin ie($version) {
    @if $is-ie {
        @if $ie-version == $version {
            @content;
        }
    }
}
