#panel{

	width: 100%;
	
}


#description{
	small{
		font-size:9.5px;
	}

}

#tint{
	background: rgba(0, 0, 0, 0.50);

	@include media(mobile){
		background: rgba(0, 0, 0, 0.0);
	}

	
	
}

#hide-title{
	color:inherit;
	margin-bottom: 115px;
	padding: 5px 0px 5px 0;
	position: relative;
	top: -15px;

}

#bigimage{

	width: 100%;

	@include media(mobile){
		display: none;
	}
}


#smallimage, #hide-title{
	@include media($min-width:640px){
		display: none;
	}
}



.home-slider{
	padding:0;
	margin-bottom:0px;
	position: relative;
	margin: auto;
	a{
		color:inherit;
		text-decoration: underline;
	}
	
	

	@include media(mobile){
		padding:0px;
		margin-bottom: 0;
	}
	.item{

		position: relative;
		text-align: left;
		
		
	
		

		&--title{

			img{
				margin-bottom: 20px;
				max-height: 200px;
				width: auto;
				
				margin-left: 45px;
			
				@include media($max-width:1400px){
				
					max-height: 150px;
				}
		

				@include media($max-width:1100px){
					padding:0;
					text-align: left;
					max-height: 120px;
				}

				@include media($max-width:767px){
					//padding:0;
					//text-align: left;
					//max-height: 120px;
					padding:0;
					text-align: left;
					max-height: 100px;
					margin-bottom: 10px;
					
				}


				@include media(mobile){
					padding:0;
					text-align: left;
					
					max-width: 220px;
					margin: 0 auto  15px auto;

				
				}
			}

			
		}
		&--text{


			line-height:1.4em;
			font-size: 14px;
			
			text-align: left;
			
			padding: 10px;
			width: 100%;
			

			@include media($max-width:1200px){
				padding:0;
				
			}


			@include media($max-width:900px){
				padding:0;
			
				font-size: 12px;
			}

			@include media($max-width:767px){
				padding:0;
			
				font-size: 12px;
			
				float: left;
				
			}

			@include media(mobile){
				padding:0;
				
				margin: 0
			}

			
		}
		&--image{

	

			@include media($max-width:1200px){
				height: auto;
				position: relative;
				width: auto;
			}


		
	

			// padding: 0;
		
		
			
		}
		&--link{
			margin:15px 0;
			img{
				@include border-radius(20px);
				&:hover{
					opacity:0.85;
				}
			}
		}
	}//item


	#worksocial{
		float: left;
		margin-right: 10px;
		//i{ color: white;}
	
	
	
	}
	
	#description{
		float:left;
		width: 85%;

		@include media(mobile){
			padding:0;
			display: none;
		}
	}

	#workImage{
		text-align: center;
		
		
	


		
		
		
	}

	#text{
	position: absolute;
	bottom: 130px;
	z-index: 1;
	left: 40px;
	text-align: left;
	width: fit-content;

	width: 35%;
			

	@include media($max-width:1400px){
		bottom: 80px;
	}


	@include media($max-width:1200px){
		
		width: 40%;
		bottom: 40px;
	}



	@include media($max-width:900px){
		
		width: 50%;
		font-size: 12px;
	}

	@include media($max-width:767px){
		
	
		font-size: 12px;
		width: 70%;
		float: left;
		bottom: 35px;
		
	}

	@include media(mobile){
		padding:0;
		
		margin: 0;
		width: 80%;
		
		
		left: 30px;
	}
	



	@include media($max-width:1200px){
		
	}

	@include media($max-width:767px){
		
	}

	@include media(mobile){
		
	
		
		
	}
}


	

	#date{
		background: white;
		color: #00B5EC;
		text-align: left;
		float: right;
		border-left: 4px solid $blue;
		padding: 4px 20px 4px 15px;
		height: auto;
		position: absolute;
		right: 0;
		bottom: 120px;
		z-index: 2;
		border-top: 1px solid #d0d0d0;
		border-bottom: 1px solid #d0d0d0;

		@include media($max-width:1200px){
			bottom: 100px;
		}
	
		@include media($max-width:767px){
			bottom: 80px;
		}
	
		@include media(mobile){
			bottom: 50px;
		}
	}

	#date p{
		padding: 0;
		font-weight: bold;
		margin: 0;
		font-size: 15px;

		@include media($max-width:1200px){
			font-size: 13px;

		}

		@include media($max-width:1200px){
			font-size: 13px;

		}
	
		@include media(mobile){
			font-size: 11px;

		}
		
	}





	.owl-buttons{
		display:none;
	}

	.owl-pagination{
		position: relative;;
		text-align:center;
		top: -70px;
		padding-bottom:0px;
		margin-bottom: -20px;

		@include media($max-width:1200px){
			top: -50px;

		}
		

		@include media(mobile){
			top:-50px;
			width:100%;
			text-align:center;
			padding:5px 0;
			height:20px;
		}

		@include ie-lte(8){
			bottom:-20px;
		}

		.owl-page{
			display:inline-block;
			margin:5px;
			height:14px;
			width:14px;
			position:relative;
			@include border-radius(7px);
			background-color: white;

			@include ie-lte(8){
				display:block;
				float:left;
			}

			span{
				position:absolute;
				display:block;
				width:12px;
				height:12px;
				//background-color:$color-white;
				@include border-radius(6px);
				top:1px;
				left:1px;

				@include ie-lte(8){
					display:none;
				}
			}

			&:hover{
				position: relative;
				width:14px;
				height:14px;
				background-color:$grey;
				@include border-radius(6px);
				top:0px;
				left:px;
				margin: 5px;

				@include ie-lte(8){
					display:none;
				}
			

				span{
					width:12px;
					height:12px;
					@include border-radius(6px);
				}

	
			}
			&.active{
				background-color:$blue;
			}	
		} //owl-page
	} //owl-pagination
} //home-slider