.contact-us{
	.contact-form{
		padding:0 0 20px;
		@include media(mobile){
			width:100%;
			float:left;
		}
		.row{
			img{
				margin-bottom:10px;
			}

			textarea{
				height:338px;
				max-width: 100%;
			}

			label{
				display:none;
				@include ie-lte(7){
					display:block;
				}
			}
		}//row
	}//contact-form
	.errMsg{
		color:$red;
		font-size:0.9em;
		font-style: italic;
		margin-bottom:7px;
	}
	.form_error{
		input,
		textarea{
			border-color:$red-light;
		}
	}
	.rightcol{
		padding-bottom:15px;
		.block{
			margin-bottom:5px;
			padding:10px 10px 10px 0;
			display:table;
			width:100%;
			float:left;
			@include boxSizing(border-box);

			&.blue{
				background-color:$blue;
				color:$color-white;
			}

			&.white{
				background-color:$color-white;
				.fa{color:$blue;}
			}

			.fa{
				font-size:3em;
				display: table-cell;
				width: 80px;
				text-align: center;
			}

			p{
				display:table-cell;
				vertical-align: middle;
				font-size:1.1em;
				line-height:1.1em;
				span{
					font-weight: 200;
					color:$grey;
					font-size:0.9em
				}
			}
		}//block
	}//rightcol
}//contact-us

.map{
	border-top:1px solid $grey-lightest;
	width:100%;
	float:left;
	display:block;
}//map


//----------------------------------------------------------- IE css

.contact-us{
	@include ie-lte(8){
		.grid-33{
			width:30%;
			margin-right: 3%;
		}
		.grid-66{
			width:60%;
		}
	}
}
