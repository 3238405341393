
@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    *zoom: 1;
}

@mixin media($size: false, $max-width: false, $min-width: false, $ignore-for-ie: false) {

    //convert pixel break points in to ems
    //automatically convert pixels to em's
    //$size: pem($size, $base: 16);//

    @if $is-ie {
        @if ($ignore-for-ie == false) {
            @if $size != mobile {
                @if ($ie-version == 6 and $mobile-ie6 == false) or $ie-version > 6 {
                    @content;
                }
            }
        }
    } @else {
        @if $size == desktop {
            @media (min-width: 48.063em){  //769px
                @content;
            }
        } @else if $size == tablet {
            @media (min-width: 40.063em){ //641px
                @content;
            }
        } @else if $size == mobile {    
            @media (max-width: 40.000em){ //640px
                @content;
            }
        } @else if $max-width != false {
            @media (max-width: $max-width){
                @content;
            }
        } @else if $min-width != false {
            @media (min-width: $min-width){
                @content;
            }
        } @else {
            @media (min-width: $size){
                @content
            }
        }
    }
}





@mixin ie-lte($version) {
    @if $is-ie {
        @if $ie-version <= $version {
            @content;
        }
    }
}
@mixin ie($version) {
    @if $is-ie {
        @if $ie-version == $version {
            @content;
        }
    }
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}
