footer {
	width: 100%;
	float: left;
	clear: both;
	position: relative;
	top: 8%;

	@include media(mobile) {
		text-align: center;
		top: 0;
	}

	#footer_navigation_list {
		padding: 20px 0 0;

		@include media(mobile) {
			display: block;
			width: 100%;
			clear: both;
			float: left;
			margin-bottom: 30px;
			padding: 0;
			border: 1px solid $grey-lightest !important;
			font-size: 11px;
		}

		li {
			display: inline-block;

			@include media(mobile) {
				display: block;
				width: 50%;
				margin: 0 !important;
				padding: 0 !important;
				float: left;
				@include boxSizing(border-box);
				border: 1px solid $grey-lightest !important;

				a {
					display: block;
					padding: 10px;
				}
			}

			&+li {
				border-left: 1px solid $grey-lightest;
				margin-left: 10px;
				padding-left: 10px;

				a:hover {
					color: $dark;
				}
			}

			&.none {
				@include media(mobile) {
					display: none;
				}
			}

			//none
		}
	}

	//#footer_navigation_list

	#social_media {
		position: relative;
		top: 0rem;
		padding: 20px 10px 0 0 ;
		text-align: right;

		@include media(mobile) {
			text-align: center;
		}

		a:hover {
			color: $dark;
		}
	}

	//social_media

	p {
		color: $grey;
		font-weight: 200;
		font-style: italic;
		font-size: 0.85em;
	}
}

//footer
//------------------------------------------------------- IE css

#footer_navigation_list {
	li {
		@include ie-lte(8) {
			display: block;
			float: left;
			padding: 10px 0;

			a {
				padding: 0 10px
			}
		}
	}
}

//footer_navigation_list
p.copy {
	@include ie-lte(8) {
		color: $grey;
		font-weight: 200;
		font-style: italic;
		font-size: 0.85em;
		display: block;
		margin: 10px 10px 40px;
		clear: both;
		width: 100%;
	}
}

//p.copy

#social_media {
	@include ie(8) {
		float: right;
		text-align: right;
		margin-top: 20px;
	}
}

//social_media